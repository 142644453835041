.MuiMultiSectionDigitalClock-root{
  scrollbar-width: thin;
}

.MuiMultiSectionDigitalClock-root::-webkit-scrollbar{
  width: 0.3em;
  height: 0.3em;
}

.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-button {
  background: #505050;
}
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-track-piece {
  background: #888;
}
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb {
  background: #eee;
}