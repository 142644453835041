root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
}

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

/* GOOGLE FONTS */
/* inter-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/inter-v12-latin-300.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-300.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/inter-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-regular.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/inter-v12-latin-500.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-500.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/inter-v12-latin-600.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-600.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/inter-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-700.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/inter-v12-latin-800.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-800.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* inter-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/inter-v12-latin-900.eot"); /* IE9 Compat Modes */
  src: url("./fonts/inter-v12-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter-v12-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter-v12-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/inter-v12-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter-v12-latin-900.svg#Inter")
      format("svg"); /* Legacy iOS */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animation-fadeIn {
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration: 0.8s;
  animation-delay: 300ms;
  animation-timing-function: ease-out;
}

.animation-basic {
  opacity: 0;
}

.animation-fadeIn-completed {
  opacity: 1;
}
